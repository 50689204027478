import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TGButton from "../../../../../shared/tg-button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setIsPanelClosed } from "../../../slice/loginPanelSlice";
import TGTextField from "../../../../../shared/tg-text-field";
import { t } from "i18next";
import {
  blockSpecialChar,
  getCookie,
  isvalidAlphabet,
} from "../../../../../utils/helper";
import { updateFieldData } from "../../../slice/bookingWidgetSlice";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";
import { config } from "../../../../../config/global";
import styles from "./guestUser.module.scss";

const MyTripsGuestUser = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isAuthenticated = isAuthenticate(); //  To check whether user is singed in or not
  const name = isAuthenticated
    ? sessionStorage.getItem("isROPLoggedIn") === "true"
      ? sessionStorage.getItem("FullName")
      : sessionStorage.getItem("lastName")
    : "";
  const prefilledInput =
    sessionStorage.getItem("isROPLoggedIn") === "true"
      ? name?.split(" ")[name?.split(" ").length - 1].toUpperCase()
      : name;
  const [maxLength, setMaxLength] = useState(13);
  const [isTicketNoError, setIsTicketNoError] = useState(false);
  const [ticketNo, setTicketNo] = useState("");
  const [isBookingNoError, setIsBookingNoError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [lastName, setLastName] = useState(
    isAuthenticated ? prefilledInput : ""
  );
  const [isNameError, setIsNameError] = useState(false);
  const bookingRegex = new RegExp(
    /^(?=.{6}$)(?![0-9]{6})(?![a-zA-Z]{6})[a-zA-Z0-9]{6}$/
  );
  const ticketRegex = new RegExp(/^[0-9]{13}$/);
  const nameRegex = new RegExp(/^(?!\s*$)[a-zA-Z\s]{2,}$/);

  useEffect(() => {
    if (isAuthenticated) {
      setLastName(prefilledInput);
      setIsLastNameError(true);
    }
  }, [ticketNo]);

  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");

    dispatch(updateFieldData({ fieldLabel, newValue }));
  };

  const handleBookingNum = (a: any, label: any) => {
    if (blockSpecialChar(a.target.value)) {
      if (a.nativeEvent?.inputType === "insertFromPaste") {
        setMaxLength(13);
        setTicketNo(a.target.value);
      } else {
        if (/^(0|[1-9][0-9]*)$/.test(a.target.value)) {
          console.log("first");
          setMaxLength(13);
        } else if (/^[a-zA-z0-9]{0,6}/.test(a.target.value)) {
          console.log("second");
          if (a.target.value === "") {
            setMaxLength(13);
          } else {
            setMaxLength(6);
          }
        }
      }
      setTicketNo(a.target.value.toUpperCase());
      setIsBookingNoError(false);
      if (a.target.value.length > 0 && a.target.value != null) {
        setIsTicketNoError(true);
        setTicketNo(a.target.value.toUpperCase());
        handleChangeInput(a, label);
      } else {
        setIsTicketNoError(false);
      }
    }
  };

  const handlelastName = (b: any, label: string) => {
    if (isvalidAlphabet(b.target.value)) {
      setLastName(b.target.value);
      setIsNameError(false);
      if (b.target.value.length > 0 && b.target.value != null) {
        setIsLastNameError(true);
        setLastName(b.target.value);
        handleChangeInput(b, label);
      } else {
        setIsLastNameError(false);
      }
    }
  };

  const handleBooking = () => {
    if (ticketNo.length === 6 || ticketNo.length === 13) {
      if (bookingRegex.test(ticketNo) || ticketRegex.test(ticketNo)) {
        if (nameRegex.test(lastName)) {
          handleManageBooking();
        } else {
          setIsNameError(true);
        }
      } else {
        if (lastName?.length < 2) {
          setIsNameError(true);
        }
        setIsBookingNoError(true);
      }
    } else {
      if (lastName?.length < 2) {
        setIsNameError(true);
      }
      setIsBookingNoError(true);
    }
  };

  const handleManageBooking = () => {
    const ticketNumber = ticketNo.toUpperCase();
    const username = lastName.replace(/\s/g, "").toUpperCase();
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = i18n?.language?.split("-")?.[0];
    const manageBookingQueryParams =
      "booking_no=" + ticketNumber + "&lastname=" + username + "&lang=" + lang;

    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  return (
    <div className={`${styles.mainContainer} global-content-padding`}>
      <div className={`${styles.heading}`}>{t("label_trip_header")}</div>
      <div className={`${styles.signInContent}`}>
        <div>
          <div className={`${styles.signInHeading}`}>
            {t("label_signin_header")}
          </div>
          <div className={`${styles.signInPara}`}>
            {t("label_signin_to_show")}
            <span className={`${styles.joinNowParaMobile}`}>
              {t("label_rop_member_not_now")}
            </span>
            <span>
              <Link
                to={`/${i18n.language}/signup`}
                className={`${styles.joinNowLinkMobile}`}
              >
                {t("label_join_now")}
              </Link>
            </span>
          </div>
          <div className={`${styles.joinNowMain}`}>
            <div className={`${styles.joinNowPara}`}>
              {t("label_rop_member_not_now")}
            </div>
            <Link
              to={`/${i18n.language}/signup`}
              className={`${styles.joinNowLink}`}
            >
              {t("label_join_now")}
            </Link>
          </div>
        </div>
        <TGButton
          label={t("label_signin_header")}
          variant="primary"
          buttonSize="sm"
          customClassName={styles.myTripsSignInBtn}
          onClick={() => dispatch(setIsPanelClosed(true))}
        />
      </div>
      <div>
        <div className={styles.manageBookingMain}>
          <div className={styles.manageBookingMainTextField}>
            <TGTextField
              label={t("label_manage_booking_reference")}
              placeholderText={t("label_manage_booking_reference")}
              type="text"
              variant={true}
              customInputClassName={styles["bookRefTextField"]}
              onTextChange={(e, label) => handleBookingNum(e, label)}
              value={ticketNo || ""}
              maxlength={maxLength}
              isError={isBookingNoError}
              errorLabel={t("label_manage_example_error")}
            />
            <ul className={styles.customTextColor}>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_booking_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_ticket_example")}
              </li>
            </ul>
          </div>
          <div className={styles.manageBookingMainTextField}>
            <TGTextField
              label={t("label_manage_last_name")}
              placeholderText={t("label_manage_last_name")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handlelastName(e, label)}
              value={lastName || ""}
              maxlength={40}
              isError={isNameError}
              errorLabel={t("label_manage_last_name_error")}
            />
          </div>
        </div>
      </div>
      <TGButton
        label={t("label_book_widget_manage_booking")}
        variant="primary"
        buttonSize="lg"
        customClassName={styles.bookingButtonCheckInGuestUser}
        onClick={handleBooking}
        disabled={!isTicketNoError || !isLastNameError}
        aria-label="Manage Booking button"
      />
    </div>
  );
};

export default MyTripsGuestUser;
