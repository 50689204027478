import React from "react";
import { MyTripsSignedUser } from "./signeduser";
import MyTripsGuestUser from "./guestuser";
import { useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";

export const MyTrips = () => {
  const { userName } = useSelector((state: RootState) => state?.osciReducer?.loginPanel);

  return <>{userName ? <MyTripsSignedUser /> : <MyTripsGuestUser />}</>;
};
